body.instagram-modal-open {
    .instagram-modal-background {
        display: block;
    }
    #instagram-modal {
        display: block;
    }
}

.instagram-modal-background {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    margin: auto;
    background-color: rgba(#095e5c, 0.9);
    @include modalBackgroundAnimation();
    z-index: 5;

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
    }
}

#instagram-modal {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    border-bottom: 3px solid #095e5c;
    -webkit-overflow-scrolling: touch; //add intertia scrolling to iOS
    @include modalForegroundAnimation();
    z-index: 500;
    opacity: 0;

    .close-modal {
        width: 60px;
        height: 60px;
        background-color: $pale-teal;
        color: #095e5c;
        border-radius: 50%;
        position: fixed;
        top: 25px;
        right: 30px;
        z-index: 5;
        border: none;
        font-size: 25px;
        cursor: pointer;
        padding: 0;

        &:focus {
            outline-style: dotted;
            outline-color: #095e5c;
            outline-width: 1px;
        }

        &:hover {
            background-color: rgba($pale-teal, 0.8);
        }
    }

    .load-more {
        color: #095e5c;
        background-color: $pale-teal;
        font-family: source-serif-pro, serif;
        font-style: normal;
        font-weight: 700;
        cursor: pointer;
        font-size: 17px;

        &:hover {
            background-color: rgba($pale-teal, 0.8);
        }

        span {
            font-size: 25px;
            display: block;
        }
    }

    .photo,
    .load-more,
    .empty-box {
        width: 100vw;
        height: 100vw;
        float: left;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        border: 3px solid #095e5c;
        border-bottom: none;

        @media screen and (min-width: 768px) {
            border: none;
            border-left: 3px solid #095e5c;
            border-top: 3px solid #095e5c;
            width: 33.3vw;
            height: 33.3vw;
        }

        @media screen and (min-width: 1024px) {
            width: 25vw;
            height: 25vw;
        }

        @media screen and (min-width: 768px) and (max-width: 1023px) {
            &:nth-of-type(3n) {
                border-right: 3px solid #095e5c;
            }
        }

        @media screen and (min-width: 1024px) {
            &:nth-of-type(4n) {
                border-right: 3px solid #095e5c;
            }
        }

        img {
            float: left;
        }

        .time,
        .hashtag,
        .total-posts {
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            z-index: 3;
            color: white;
            font-family: acumin-pro-extra-condensed, sans-serif;
            font-style: normal;
            font-weight: 700;

            @media screen and (min-width: 768px) {
                font-size: 16px;
                width: 33.3vw;
                height: 33.3vw;
            }

            @media screen and (min-width: 1024px) {
                width: 25vw;
                height: 25vw;
            }

        }

        .time {
            background: rgba(#095e5c, 0.8);

            span {
                display: block;
                font-size: 50px;
                padding: 15px;

                @media screen and (min-width: 768px) {
                    font-size: 40px;
                    padding: 5px;
                }
            }
        }

        .hashtag {
            background: rgba(#ffb821, 0.8);
            font-size: 40px;

            @media screen and (min-width: 320px) {
                font-size: 40px;
            }

            @media screen and (min-width: 768px) {
                font-size: 28px;
            }

            @media screen and (min-width: 901px) {
                font-size: 30px;
            }

            @media screen and (min-width: 1200px) {
                font-size: 40px;
            }
        }

        .total-posts {
            background: rgba(#ff8400, 0.8);

            span {
                display: block;
                font-size: 50px;
                padding: 15px;

                @media screen and (min-width: 768px) {
                    font-size: 40px;
                    padding: 5px;
                }
            }

        }
    }

    .empty-box {
        .time {
            background: rgba(#095e5c, 1);
        }

        .hashtag {
            background: rgba(#ffb821, 1);
        }

        .total-posts {
            background: rgba(#ff8400, 1);
        }
    }

}
