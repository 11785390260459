/* For some reason the current compiler setup forgets the mixins previously imported.
The temporary fix is to import them on each page using them */
@import 'pub/utils/colors';
@import 'pub/utils/mixins';
@import './node_modules/object-fit-images/preprocessors/mixin.scss';



@each $article, $color in $articles {
    body #article-modal.#{$article} #full-photo-background {
        &:after {
            background-color: $color;
        }
    }
}

#full-photo-background {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    // transition: 0.25s background-color 0s ease-in-out;

    &:after {
        content: '';
        position: fixed;
        display: block;
        top: -50%;
        left: -50%;
        right: -50%;
        margin: auto;
        width: 200%;
        height: 200%;
        opacity: 0.8;
        z-index: 2;
    }

    @media screen and (min-width: 768px) {
        align-items: center;
    }

    img.background-photo {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        width: auto !important;
        min-width: 100%;
        max-width: 150%;
        height: auto !important;
        min-height: 100%;
        max-height: 150%;
        margin: 0 auto;
        @include object-fit(cover, center);
        z-index: 1;
    }

    .text {
        position: relative;
        max-width: 80%;
        margin-top: 100px;
        margin-bottom: 100px;
        color: $pale-teal;
        // transition: 0.25s color 0s ease-in-out;
        z-index: 3;

        &.quote {
            font-size: 22px;
            line-height: 38px;
            font-family: freight-text-pro, serif;
            font-style: normal;
            font-weight: 400;
            width: 100%;
            padding: 20px;
            padding-bottom: 100px;

            @media screen and (min-width: 768px) {
                font-size: 37px;
                line-height: 42px;
                max-width: 545px;
                padding: 0;
            }

            @media screen and (min-width: 1024px) {
                max-width: 750px;
                padding: 0;
            }

            p {
                padding: 0;
                margin: 0;

                &:first-of-type:before,
                &:nth-last-of-type(2):after {
                    font-size: 22px;
                    line-height: 38px;

                    @media screen and (min-width: 768px) {
                        font-size: 37px;
                        line-height: 42px;
                    }
                }
                &:first-of-type:before {
                    content: '“';
                    position: absolute;
                    top: 17px;
                    left: 8px;

                    @media screen and (min-width: 768px) {
                        top: -5px;
                        left: -20px;
                    }
                }
                &:nth-last-of-type(2):after {
                    content: '”';
                    position: relative;
                    bottom: 0;
                    right: 0;
                }
            }

            .subtext {
                font-family: acumin-pro-extra-condensed, sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
            }

        }

        &.title {
            text-align: center;

            h1 {
                font-family: acumin-pro-extra-condensed, sans-serif;
                font-style: normal;
                font-weight: 700;
                text-transform: uppercase;
                padding: 0;
                margin: 0;
                margin-top: 6px;
                margin-bottom: 20px;
                font-size: 30px;
                line-height: 33px;

                @media screen and (min-width: 768px) {
                    font-size: 48px;
                    margin-bottom: 50px;
                    margin-top: 15px;
                    line-height: 53px;
                }
            }

            time {
                font-size: 24px;
                font-family: acumin-pro-extra-condensed, sans-serif;
                font-style: normal;
                font-weight: 700;
            }

        }
    }
}
