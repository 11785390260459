/* For some reason the current compiler setup forgets the mixins previously imported.
The temporary fix is to import them on each page using them */
@import 'pub/utils/colors';
@import 'pub/utils/mixins';
@import './node_modules/object-fit-images/preprocessors/mixin.scss';

@each $article, $color in $articles {
    body #article-modal.#{$article} #single-column  {
        h1,
        time {
            color: $color;
        }

        .close-button {
            border-color: $color;
            color: $color;

            &:before,
            &:after {
                background-color: $color;
            }
        }
    }
}

#single-column {
    margin: 0 auto;
    padding: 20px 0 33.333vh 0;
    max-width: 80%;
    overflow-wrap: break-word;
    word-wrap: break-word;

    @media screen and (min-width: 768px) {
        width: 565px;
        padding-top: 155px;
    }

    @media screen and (min-width: 1024px) {
        width: 690px;
    }

    img {
        max-width: 100% !important;
        height: auto !important;
    }

    &:after {
        content: '';
        position: fixed;
        display: block;
        bottom: -66.666vh; /* scrolling fast in iOS creates a gap if this is set to 0 */
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100%;
        height: 100vh;
        background: linear-gradient(to bottom, rgba($pale-teal,0) 0%, rgba($pale-teal,1) 33.333%, rgba($pale-teal,1) 100%);
        pointer-events: none;
    }

    h1 {
        max-width: 80%;
        font-family: acumin-pro-extra-condensed, sans-serif;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        padding: 0;
        margin: 6px 30px 20px 0;
        font-size: 30px;
        line-height: 33px;
        transition: 0.25s color 0s ease-in-out;

        @media screen and (min-width: 768px) {
            font-size: 48px;
            margin: 15px 0 50px 0;
            line-height: 53px;
        }
    }

    img {
        position: relative;
        display: block;
        height: auto !important;

        &:not([style*='float']),
        &:not([STYLE*='float']) {
            width: 100% !important;
        }

        &[style*='float'],
        &[STYLE*='float'] {
            width: 100% !important;
            float: none !important;
        }

        &[style*='float: left'],
        &[style*='float:left'],
        &[STYLE*='float: left']
        &[STYLE*='float:left'] { //Edge uppercases known attributes
            @media screen and (min-width: 1024px) {
                max-width: 55%;
                padding: 10px 40px 10px 0;
                float: left !important;
            }
        }

        &[style*='float: right'],
        &[style*='float:right'],
        &[STYLE*='float: right']
        &[STYLE*='float:right'] { //Edge uppercases known attributes
            @media screen and (min-width: 1024px) {
                max-width: 55%;
                padding: 10px 0 10px 40px;
                float: right !important;
            }
        }
    }

    time {
        font-size: 20px;
        font-family: acumin-pro-extra-condensed, sans-serif;
        font-style: normal;
        font-weight: 700;
        transition: 0.25s color 0s ease-in-out;
    }
}
