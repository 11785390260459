/* For some reason the current compiler setup forgets the mixins previously imported.
The temporary fix is to import them on each page using them */
@import 'pub/utils/colors';
@import 'pub/utils/mixins';
@import './node_modules/object-fit-images/preprocessors/mixin.scss';

body.article-modal-open {
    main {
        position: fixed;
    }
}

body.article-modal-hidden {
    .article-modal-background,
    #article-modal {
        display: none;
    }
}

.article-modal-background {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: $pale-teal;
    @include modalBackgroundAnimation;
    z-index: 199;

    @each $article, $color in $articles {
        &.#{$article} {
            background-color: $color;
        }
    }
}

#article-modal {
    position: fixed;
    display: block;
    width: 100vw;
    height: 100vh;
    font-family: freight-text-pro, serif;
    font-style: normal;
    font-weight: 400;
    color: $dark-teal;
    font-size: 16px;
    line-height: 24px;
    background-color: $pale-teal;
    overflow: hidden;
    -webkit-overflow-scrolling: touch; //add intertia scrolling to iOS
    @include modalForegroundAnimation;
    z-index: 200;


    &.single-column-layout-modal,
    &.undefined-layout-modal {
        .question-button {
            color: $dark-teal;

            @media screen and (min-width: 1024px) {
                color: $dark-teal;
            }
        }

        .close-button {
            color: $dark-teal;

            svg {
                path {
                    &[stroke*="0"],
                    &[STROKE*="0"] //Edge uppercases known SVG attributes
                    {
                        stroke: $dark-teal !important;

                        @media screen and (min-width: 1024px) {
                            stroke: $dark-teal !important;
                        }
                    }
                }
            }
        }

        @each $article, $color in $articles {
            &.#{$article} {

                .question-button {
                    color: $color;
                }

                .close-button {
                    color: $color;

                    svg {
                        path {
                            &[stroke*="0"],
                            &[STROKE*="0"] { //Edge uppercases known SVG attributes
                                stroke: $color !important;

                                @media screen and (min-width: 1024px) {
                                    stroke: $color !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.two-column-layout-modal {
        .question-button {
            color: $dark-teal;

            @media screen and (min-width: 1024px) {
                color: $dark-teal;
            }
        }

        .close-button {
            color: $dark-teal;

            @media screen and (min-width: 1024px) {
                color: $dark-teal;
            }

            svg {
                path {
                    &[stroke*="0"],
                    &[STROKE*="0"] //Edge uppercases known SVG attributes
                    {
                        stroke: $dark-teal !important;

                        @media screen and (min-width: 1024px) {
                            stroke: $dark-teal !important;
                        }
                    }
                }
            }
        }
    }

    &.full-photo-background-layout-modal {

        .question-button {
            color: $pale-teal;

            @media screen and (min-width: 1024px) {
                color: $pale-teal;
            }
        }

        .close-button {
            color: $pale-teal;

            svg {
                path {
                    &[stroke*="0"],
                    &[STROKE*="0"] //Edge uppercases known SVG attributes
                    {
                        stroke: $pale-teal !important;

                        @media screen and (min-width: 1024px) {
                            stroke: $pale-teal !important;
                        }
                    }
                }
            }
        }
    }

    @each $article, $color in $articles {
        &.#{$article} {
            &.about-modal-open {
                .question-button {
                    color: $color;
                }
            }

            .about-modal {
                &:before,
                &:after {
                    background-color: $color;
                }
            }

            .prev-button:after, .next-button:after {
                background-color: $color;
            }

            p a {
                color: $color !important;
            }
        }
    }

    &.about-modal-open {
        .about-modal {
            display: block;
        }
    }

    .about-modal {
        position: fixed;
        display: none;
        top: 75px;
        right: 5px;
        width: 80%;
        max-width: 620px;
        height: auto;
        margin: 0 auto;
        padding: 10px 30px;
        color: $pale-teal;
        z-index: 400;

        @media screen and (min-width: 768px) {
            top: 110px;
        }
        @media screen and (min-width: 1024px) {
            top: 120px;
            padding: 20px 50px;
        }

        &:before {
            content: '';
            position: absolute;
            display: block;
            width: 30px;
            height: 30px;
            transform: rotateZ(135deg);
            top: -15px;
            right: 58px;
            background-color: $dark-teal;
            z-index: -1;

            @media screen and (min-width: 768px) {
                right: 91px;
            }

            @media screen and (min-width: 1024px) {
                right: 96px;
            }
        }

        &:after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            margin: auto;
            background-color: $dark-teal;
            z-index: -1;
        }

        h3 {
            position: relative;
            font-size: 24px;
            line-height: 28px;

            @media screen and (min-width: 1024px) {
                font-size: 30px;
                line-height: 33px;
            }
        }
    }

    .question-button {
        position: fixed;
        top: 20px;
        right: 60px;
        z-index: 300;

        @media screen and (min-width: 768px) {
            top: 45px;
            right: 90px;
        }

        @media screen and (min-width: 1024px) {
            color: $dark-teal;
        }
    }

    .close-button {
        position: fixed;
        top: 20px;
        right: 20px;
        color: $dark-teal;
        z-index: 300;

        @media screen and (min-width: 768px) {
            top: 45px;
            right: 40px;
        }

        @media screen and (min-width: 1024px) {
            color: $dark-teal;
        }

    }

    .prev-button, .next-button {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-shrink: 0;
        flex-grow: 0;
        right: 0;
        width: 40px;
        height: 40px;
        outline: none;
        overflow: hidden;
        pointer-events: all;
        z-index: 300;

        &:after {
            content: '';
            position: fixed;
            right: 0;
            width: 50px;
            height: 40px;
            transform: translateX(10px);
            transition: 0.25s transform 0s ease-in-out, 0.25s background-color 0s ease-in-out;
            z-index: 300;
        }

        &:hover {
            &:after {
                transform: translateX(0);
            }
        }

        svg {
            position: relative;
            width: 13px;
            height: 13px;
            pointer-events: none;
            user-select: none;
            z-index: 301;

            polygon {
                fill: $dark-teal;
                stroke: $dark-teal;
            }
        }
    }
    .prev-button {
        bottom: 42px;

        &:after {
            bottom: 42px;
        }
    }
    .next-button {
        bottom: 0;

        &:after {
            bottom: 0;
        }
    }
    &.no-prev .prev-button {
        opacity: 0.5;
        pointer-events: none;
    }
    &.no-next .next-button {
        opacity: 0.5;
        pointer-events: none;
    }
    &.story .prev-button, &.story .next-button {
        svg {
            polygon {
                fill: $pale-teal;
                stroke: $pale-teal;
            }
        }
    }

    .content {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background-color: $pale-teal;
        overflow-y: auto;
        z-index: 198;

        &.animate-to-previous {
            @include animateToPrevious;
        }
        &.animate-from-previous {
            @include animateFromPrevious;
        }
        &.animate-to-next {
            @include animateToNext;
        }
        &.animate-from-next {
            @include animateFromNext;
        }
    }

    .error-message {
        padding-top: 50vh;
        text-align: center;
    }

    p a {
        font-weight: 800;
        text-decoration: none;
        transition: 0.25s color 0s ease-in-out;
    }

}
