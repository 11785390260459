body .bodymovin {

    &.bodymovin-button {
        pointer-events: all;
        touch-action: auto;

        svg {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            min-height: 100%;
            margin: auto;
            user-select: none;
            pointer-events: none;
            touch-action: none;
            z-index: 0;

            path {
                vector-effect: non-scaling-stroke;

                html.no-vectoreffect & {
                    stroke-width: 0.4px !important;
                }
            }
        }
    }

    &.bodymovin-dot {
        pointer-events: all;
        touch-action: auto;

        svg {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: auto !important;
            height: 100%;
            margin: auto;
            user-select: none;
            pointer-events: none;
            touch-action: none;
            z-index: 0;

            /* overriding the g filter, rect opacity fixes a bug in Android */
            html.is-android & {
                defs {
                    mask {
                        g {
                            filter: unset;
                        }
                        rect[opacity="0"] {
                            opacity: 1 !important;
                        }
                    }
                }
            }

            path {
                vector-effect: non-scaling-stroke;

                &[fill*="255"],
                &[FILL*="255"] //Edge uppercases known SVG attributes
                {
                    fill: transparent !important;
                }
            }

            g.arrow-block {
                display: none;
            }
        }
    }

}
