html {
    /* styles to use before typekit script is loaded */
    &:not(.wf-active) {
        * {
            color: transparent !important;
        }
    }
}

body {
    font-family: freight-text-pro, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: acumin-pro-extra-condensed, sans-serif;
}

cite {
    font-size: 12px;
}
