/* For some reason the current compiler setup forgets the mixins previously imported.
The temporary fix is to import them on each page using them */
@import 'pub/utils/colors';
@import 'pub/utils/mixins';
@import './node_modules/object-fit-images/preprocessors/mixin.scss';

@each $article, $color in $articles {
    body #article-modal.#{$article} #two-column {
        .top-photo,
        .big-photo {
            &:before {
                background-color: $color;
            }
        }
    }
}

#two-column {
    width: 100%;

    .top-photo,
    .big-photo {
        &:before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            opacity: 0.8;
            z-index: 2;
        }
    }

    .left {
        position: relative;
        width: 100%;
        z-index: 210;

        @media screen and (min-width: 1024px) {
            width: 50%;
            float: left;
            position: fixed;
        }

        .big-photo {
            position: relative;
            width: 100%;
            height: 68vh;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            &:before {
                transition: 0.25s background-color 0s ease-in-out;
            }

            @media screen and (min-width: 1024px) {
                height: 100vh;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: auto !important;
                min-width: 100%;
                max-width: 150%;
                height: auto !important;
                min-height: 100%;
                max-height: 150%;
                margin: auto;
                @include object-fit(cover, center);
            }
        }

        .top-photo {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 34vh;
            overflow: hidden;

            &:before {
                transition: 0.25s background-color 0s ease-in-out;
            }

            @media screen and (min-width: 1024px) {
                width: 100%;
                height: 68vh;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: auto !important;
                min-width: 100%;
                max-width: 150%;
                height: auto !important;
                min-height: 100%;
                max-height: 150%;
                margin: auto;
                @include object-fit(cover, center);
            }
        }

        .bottom-photos {
            width: 100%;
            height: 27vh;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (min-width: 1024px) {
                height: 32vh;
            }

            .container {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 50%;
                height: 100%;
                float: left;
                overflow: hidden;

                img {
                    width: auto !important;
                    min-width: 100%;
                    max-width: 150%;
                    height: auto !important;
                    min-height: 100%;
                    max-height: 150%;
                    @include object-fit(cover, center);

                    @media (min-width: 1024px) {
                        margin-top: 0;
                    }
                }

            }
        }

    }

    .right {
        width: 100%;
        clear: both;

        @media screen and (min-width: 1024px) {
            width: 50%;
            float: right;
        }

        .container {
            margin: 0 auto;
            padding: 20px 0 33.333vh 0;
            max-width: 80%;
            overflow-wrap: break-word;
            word-wrap: break-word;

            @media screen and (min-width: 768px) {
                width: 564px;
                padding-top: 155px;
            }

            @media screen and (min-width: 1024px) {
                width: 450px;
            }

            &:after {
                content: '';
                position: fixed;
                display: block;
                bottom: -66.666vh; /* scrolling fast in iOS creates a gap if this is set to 0 */
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 100%;
                height: 100vh;
                background: linear-gradient(to bottom, rgba($pale-teal,0) 0%, rgba($pale-teal,1) 33.333%, rgba($pale-teal,1) 100%);
                pointer-events: none;
            }

            img {
                max-width: 100% !important;
                height: auto !important;
            }

        }
    }

    .title {
        position: relative;
        display: block;
        max-width: 100%;
				width: 100%;
        margin: -15px auto 0 auto;
        text-align: center;
        font-size: 0;
        line-height: 0;
        color: $pale-teal;
        z-index: 5;

        @media screen and (min-width: 768px) {
            margin-top: -24px; /* h1 font size / 2 */
        }
    }

    h1 {
        max-width: 80%;
        font-family: acumin-pro-extra-condensed, sans-serif;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        padding: 0;
        margin: 0 auto;
        font-size: 30px;
        line-height: 33px;

        @media screen and (min-width: 768px) {
            font-size: 48px;
            line-height: 53px;
        }
    }


    time {
        margin-bottom: 6px;
        font-size: 24px;
        font-family: acumin-pro-extra-condensed, sans-serif;
        font-style: normal;
        font-weight: 700;

        @media screen and (min-width: 768px) {
            margin-bottom: 15px;
        }
    }
}
