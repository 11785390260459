#sculpture-container {
    position: relative;
    width: 100vw;
    min-height: 720px;
    height: 100vh;
    background-image: url('#{$img-path}orange-photo-mobile.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 415px) {
        background-image: url('#{$img-path}orange-photo-tablet.jpg');
    }

    @media screen and (min-width: 769px) {
        background-image: url('#{$img-path}orange-photo-desktop.jpg');
    }

    #living-sculpture {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 80vw;
        height: 80vh;

        @media screen and (min-width: 1025px) {
            width: 80vw;
            height: 60vh;
        }

        .dot-container {
            position: absolute;
            width: auto;
            height: 34px;
            outline: none;
            pointer-events: auto;
            touch-action: auto;
            transition: opacity 0.5s ease-in-out;
            z-index: 2;

            &.flag-left {
                transform: rotate(180deg);

                .hashtag-label {
                    transform: rotate(180deg);
                    display: inline-block;
                    position: relative;
                    top: 2px;
                    left: 5px;
                }
            }

            &:hover, &.touch-active {
                z-index: 5;
            }

            &.touch-active {

                .dot-label-wrapper {
                    pointer-events: all !important;
                }
            }
        }
        a.dot {
            position: relative;
            display: block;
            width: 34px;
            height: 34px;
            outline: none;
            overflow: hidden;
            pointer-events: all;
            touch-action: auto;
            z-index: 1;
        }

        .dot-label-wrapper {
            position: absolute;
            display: block;
            top: 0;
            left: 34px;
            height: 100%;
            width: auto;
            overflow: hidden;
            z-index: 2;
            pointer-events: none;
        }

        .dot-label {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: auto;
            height: 100%;
            margin: 0;
            padding: 0 5px;
            white-space: nowrap;
            font-family: acumin-pro-extra-condensed, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 32px;
            color: $dark-teal;
            transform: translateX(-101%);
            pointer-events: none;
            touch-action: none;
            z-index: 1;
        }

        svg.dot-arrow {
            position: relative;
            width: 12px;
            height: 12px;
            margin: auto auto -1px 12px;
            fill: $dark-teal;
            stroke: $dark-teal;
            stroke-width: 20px;
            z-index: 1;
        }

        .bodymovin-wrapper {
            height: 34px;
            width: 34px;
        }

        .bodymovin-dot {
            svg {
                /* (original width * (desired width / original height)) fixes bug in Safari */
                width: calc(249px * (34 / 45)) !important;
            }
        }

        svg path[stroke*="0"],
        svg path[STROKE*="0"] {
            stroke: #ffd084 !important;
        }
        svg path[fill*="0"],
        svg path[FILL*="0"] {
            fill: #ffd084 !important;
        }
        .dot-label {
            color: #095e5c;
            background-color: #ffd084;
        }
        .dot-arrow polygon {
            fill: #095e5c;
            stroke: #095e5c;
        }

        g.dot {

            path {
                fill: transparent !important;
            }
        }


        svg {
            pointer-events: none;
            z-index: 1;

            .svg-dot {
                fill: #095e5c;
            }

            .line {
                stroke: #095e5c;
                stroke-width: 2px;
            }
        }
    }

    .laumeier-logo {
        position: absolute;
        top: 40px;
        left: 40px;

        @media screen and (min-width: 415px) {
            top: 50px;
            left: 50px;
        }

        @media screen and (min-width: 769px) {
            top: 75px;
            left: 75px;
        }
    }

    .text {
        display: block;
        font-family: acumin-pro-extra-condensed, sans-serif;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        color: #ff8400;
        font-size: 77px;
        line-height: 69px;
        width: auto;
        padding: 0 40px;

        @media screen and (min-width: 375px) {
            font-size: 90px;
            line-height: 80px;
        }

        @media screen and (min-width: 414px) {
            font-size: 94px;
            line-height: 88px;
        }

        @media screen and (min-width: 415px) {
            letter-spacing: 6px;
            font-size: 145px;
            line-height: 127px;
        }

        @media screen and (min-width: 750px) {
            width: 720px;
            letter-spacing: 6px;
            font-size: 175px;
            line-height: 150px;
            padding: 0 5px;
        }
    }

    .question-button {
        position: absolute;
        top: 30px; //make sure this is synced with the close button of its modal
        right: 30px; //make sure this is synced with the close button of its modal
        color: #095e5c;

        @media screen and (min-width: 768px) {
            top: 60px; //make sure this is synced with the close button of its modal
            right: 75px; //make sure this is synced with the close button of its modal
        }

        svg {
            path {
                stroke: #095e5c;
            }
        }
    }

    .start-exploring-button {
        position: absolute;
        bottom: 30px;
        left: 50%;
        margin-left: -94px;
    }

}
