#park-map-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
    width: 100vw;
    height: 100vh;
    min-height: 960px;
    background: #28b0ab;
    background: #28b0ab url('#{$img-path}map-background-topographical.jpg') no-repeat;
    background-size: cover;
    overflow: hidden;
		outline: none;

    @media screen and (min-width: 1024px) {
        min-height: 900px;
    }

    &:before {
        content: '';
        position: absolute;
        display: block;
        top: -30px;
        width: 100%;
        height: 150px;
        background: linear-gradient(to bottom, rgba(#000, 0.3), transparent 40%);
        pointer-events: none;
        touch-action: none;
        z-index: 150;
    }

    .back-to-top-button {
        bottom: 30px;
        left: 0;
        right: 0;
        margin: 0 auto;
        color: $dark-teal;
        z-index: 5;

        @media screen and (min-width: 1024px) {
            bottom: auto;
            top: 75px;
            bottom: unset;
            left: 75px;
            right: unset;
            margin: 0;
        }
    }

    .clouds {
        position: absolute;
        width: 100%;
        min-width: 500px; //1000px / 2
        height: 100%;
        min-height: 512px; //1025px / 2
        margin: auto;
        background-image: url('#{$img-path}/map-clouds.png');
        background-repeat: no-repeat;
        background-size: contain;
        pointer-events: none;
        touch-action: none;
    }
    .clouds01 {
        top: -150%;
        right: -162%;
        width: 300%;
        height: 300%;
        z-index: 4;
    }
    .clouds02 {
        bottom: -150%;
        left: -38%;
        width: 300%;
        height: 300%;
        z-index: 3;
    }

    #map-filters {
        position: absolute;
        right: 0;
        top: 0;
        width: 55px;
        height: 90px;
        text-align: right;
        z-index: 5;

        @media screen and (min-width: 768px) {
            width: 100px;
            height: 150px;
        }

        @media screen and (min-width: 1024px) {
            right: 75px;
            top: 75px;
        }

        html body.map-filters-menu-open & {
            width: auto !important;

            h3 {
                opacity: 0;

                @media screen and (min-width: 1024px) {
                    opacity: 1;
                }
            }
            .filters {
                transform: translateX(-100vw);

                @media screen and (min-width: 1024px) {
                    transition: none;
                    transform: translateX(0);
                }
            }
        }

        h3 {
            position: relative;
            display: inline-block;
            width: 55px;
            height: 90px;
            margin: 0;
            padding: 10px;
            font-family: acumin-pro-extra-condensed, sans-serif;
            font-style: normal;
            font-size: 16px;
            font-weight: 700;
            line-height: 65px;
            color: #44c6c1;
            text-align: center;
            text-transform: uppercase;
            border-bottom: 1px solid #9ff0ee;
            box-shadow: 3px -1px 15px 1px rgba(#000, 0.5);
            background: #9ff0ee;
            user-select: none;
            cursor: pointer;

            @media screen and (min-width: 768px) {
                width: 100px;
                height: 150px;
                line-height: 140px;
                color: #58ded9;
            }

            @media screen and (min-width: 1024px) {
                width: auto;
                height: auto;
                line-height: inherit;
                color: $dark-teal;
                font-size: 22px;
                padding: 10px 0;
                background: none;
                box-shadow: none;
                color: $dark-teal;
                cursor: default;
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 55px;
                left: 0;
                right: 0;
                width: 35px;
                height: 1px;
                margin: auto;
                line-height: 140px;
                background: #44c6c1;

                @media screen and (min-width: 768px) {
                    top: 92px;
                    background: #58ded9;
                }

                @media screen and (min-width: 1024px) {
                    display: none;
                }
            }


            span {
                display: none;
                color: #9ff0ee;

                @media screen and (min-width: 1024px) {
                    display: inline;
                    color: inherit;
                }
            }
        }

        .filters {
            position: relative;
            display: block;
            top: -90px;
            right: -100vw;
            width: 100vw;
            height: 90px;
            text-align: center;
            background: rgba(#9ff0ee, 0.9);
            box-shadow: 3px -1px 25px 1px rgba(#000, 0.5);
            transform: translateX(0);
            transition: transform 0.25s ease-in-out;
            z-index: 151;

            @media screen and (min-width: 768px) {
                top: -150px;
                height: 150px;
            }
            @media screen and (min-width: 1024px) {
                top: 0;
                right: 0 !important;
                width: auto;
                height: auto;
                text-align: right;
                background: none;
                box-shadow: none;
                transition: none;
                transform: translateX(0);
            }

            .hide {
                position: absolute;
                left: 0;
                height: 90px;
                width: 60px;
                text-align: center;
                line-height: 65px;
                display: inline-block;
                font-family: acumin-pro-extra-condensed, sans-serif;
                font-style: normal;
                font-weight: 700;
                color: #44c6c1;
                text-transform: uppercase;
                font-size: 14px;
                margin: 0;
                padding: 10px 10px 10px 0;
                text-decoration: none;

                @media screen and (min-width: 768px) {
                    font-size: 16px;
                    line-height: 125px;
                    padding-left: 30px;
                    color: #58ded9;
                }

                @media screen and (min-width: 1024px) {
                    display: none;
                }

                &:after {
                    display: block;
                    content: '';
                    width: 20px;
                    height: 1px;
                    position: absolute;
                    top: 55px;
                    left: 15px;
                    background: #44c6c1;

                    @media screen and (min-width: 768px) {
                        top: 85px;
                        left: 30px;
                        width: 22px;
                        background: #58ded9;
                    }
                }
            }

            ul {
                li {
                    white-space: nowrap;

                    &.active {
                        pointer-events: all;
                    }
                }
            }

            ul#decade-filter {
                position: relative;
                top: 13px;
                margin: 0 auto;
                padding: 0;
                display: inline-block;
                font-family: source-serif-pro, serif;
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                text-align: center;
                color: $dark-teal;
                list-style: none;
                border-top: 1px solid #27beb8;
                width: auto;

                @media screen and (min-width: 768px) {
                    font-size: 24px;
                    width: auto;
                    top: 25px;
                }

                @media screen and (min-width: 1024px) {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: auto;
                    border: none;
                    border-right: 1px solid #9ff0ee;
                    margin-top: 65px;
                    margin-bottom: 45px;
                    font-size: 18px;
                    float: none;
                    margin-left: auto;
                    top: auto;
                    text-align: right;
                }

                li {
                    padding: 5px 5px;
                    display: inline-block;
                    cursor: pointer;
                    position: relative;
                    transition: all .5s ease;

                    @media screen and (min-width: 768px) {
                        padding: 10px 12px;
                    }

                    @media screen and (min-width: 1024px) {
                        display: block;
                        padding: 10px 20px;

                        &:hover {
                            padding-right: 50px;

                            &:after {
                                width: 20%;
                            }

                        }
                    }

                    &:after {
                        display: block;
                        content: '';
                        width: 0;
                        height: 1px;
                        background: #27beb8;
                        position: absolute;
                        bottom: 5;
                        transition: width .5s ease;
                        will-change: width;

                        @media screen and (min-width: 1024px) {
                            right: 50px;
                            background: #9ff0ee;
                        }
                    }

                    html.no-touchevents &:hover, &.active {

                        @media screen and (min-width: 1024px) {
                            padding-right: 50px;
                        }

                        &:after {
                            width: 73%;

                            @media screen and (min-width: 768px) {
                                width: 64%;
                            }

                            @media screen and (min-width: 1024px) {
                                width: 30%;
                            }
                        }
                    }
                }

                &:before,
                &:after {
                    display: block;
                    content: '';
                    height: 10px;
                    width: 1px;
                    background: #27beb8;
                    position: absolute;
                    top: 0;
                    right: 0;

                    @media screen and (min-width: 1024px) {
                        right: 0;
                        height: 1px;
                        width: 10px;
                        background: #9ff0ee;
                    }
                }

                &:after {
                    left: 0;

                    @media screen and (min-width: 1024px) {
                        left: auto;
                        top: auto;
                        bottom: 0;
                    }
                }
            }

            #article-filter {
                font-family: freight-text-pro, serif;
                font-style: normal;
                font-weight: 700;
                position: relative;
                margin: 15px auto;
                list-style: none;
                font-size: 11px;
                width: auto;
                padding: 0;

                @media screen and (min-width: 768px) {
                    font-size: 22px;
                    width: auto;
                    margin-top: 40px;
                }

                @media screen and (min-width: 1024px) {
                    position: absolute;
                    top: 330px;
                    right: 0;
                    font-size: 18px;
                    border-right: 1px solid #9ff0ee;
                    width: auto;
                    margin-top: 15px;
                    padding: 0;
                }

                li {
                    display: inline-block;
                    cursor: pointer;
                    padding: 5px 13px;
                    position: relative;
                    transform: translateX(0);
                    transition: transform .5s ease;

                    @media screen and (min-width: 1024px) {
                        padding: 12px 15px;
                        display: block;
                    }

                    &:before {
                        display: block;
                        content: '';
                        width: 0;
                        height: 1px;
                        background: #27beb8;
                        position: absolute;
                        bottom: 5px;
                        padding: 0;
                        transform: translateX(0);
                        transition: width .5s ease, transform .5s ease;
                        will-change: width;

                        @media screen and (min-width: 1024px) {
                            bottom: 8px;
                            left: 25px;
                            background: #9ff0ee;
                        }
                    }

                    &:after {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        content: '';
                        margin-left: 10px;
                        position: relative;
                        top: 2px;
                        border-radius: 50%;

                        @media screen and (min-width: 768px) {
                            width: 20px;
                            height: 20px;
                            top: 5px;
                        }

                        @media screen and (min-width: 1024px) {
                            top: 2px;
                            width: 15px;
                            height: 15px;
                        }
                    }

                    &.sculpture {
                        color: $yellow;

                        &:before {
                            left: 12px;
                            @media screen and (min-width:768px) {
                                left: 13px;
                            }
                            @media screen and (min-width:1024px) {
                                left: 25px;
                            }
                        }
                        &:after {
                            background: $yellow;
                        }

                        html.no-touchevents &:hover, &.active {
                            &:before {
                                width: 52%;
                                @media screen and (min-width:768px) {
                                    width: 62.5%;
                                }
                                @media screen and (min-width:1024px) {
                                    width: 54%;
                                }
                            }
                        }
                    }

                    &.story {
                        color: $purple;

                        &:before {
                            left: 13px;
                            @media screen and (min-width:768px) {
                                left: 14px;
                            }
                            @media screen and (min-width:1024px) {
                                left: 58px;
                            }
                        }
                        &:after {
                            background: $purple;
                        }

                        html.no-touchevents &:hover, &.active {
                            &:before {
                                width: 36%;
                                @media screen and (min-width:768px) {
                                    width: 47%;
                                }
                                @media screen and (min-width:1024px) {
                                    width: 31%;
                                }
                            }
                        }
                    }

                    &.milestones {
                        color: #05daa5;

                        @media screen and (min-width: 1024px) {
                            color: $green;
                        }

                        &:before {
                            left: 12px;
                            @media screen and (min-width:768px) {
                                left: 13px;
                            }
                            @media screen and (min-width:1024px) {
                                left: 15px;
                            }
                        }
                        &:after {
                            background: #05daa5;

                            @media screen and (min-width: 1024px) {
                                background: $green;
                            }
                        }

                        html.no-touchevents &:hover, &.active {
                            &:before {
                                width: 54%;
                                @media screen and (min-width:768px) {
                                    width: 65%;
                                }
                                @media screen and (min-width:1024px) {
                                    width: 61%;
                                }
                            }
                        }
                    }

                    html.no-touchevents &:hover, &.active {
                        @media screen and (min-width: 1024px) {
                            transform: translateX(-50px);
                        }
                    }
                }

                &:before,
                &:after {

                    @media screen and (min-width: 1024px) {
                        display: block;
                        content: '';
                        height: 1px;
                        width: 10px;
                        background: #9ff0ee;
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }

                &:after {
                    top: auto;
                    bottom: 0;
                }
            }

        }
    }

    .social-links {
        position: absolute;
        bottom: 30px;
        right: 30px;
        list-style: none;
        padding: 0;
        margin: 0;
        z-index: 5;

        @media screen and (min-width: 1024px) {
            bottom: 60px;
            left: 60px;
            right: auto;
        }

        li {
            padding: 0;
            margin: 0;
            display: block;
            position: relative;

            @media screen and (min-width: 1024px) {
                display: inline-block;
                padding: 10px;
            }

            &.facebook {

            }

            &.instagram {
                top: -12.5px;

                @media screen and (min-width: 768px) {
                    top: -7.5px;
                }
                @media screen and (min-width: 1024px) {
                    top: 0;
                    left: -7.5px;
                }

                /* if there is no vector-effect property allowed (IEdge) the lines will be thicker */
                html.no-vectoreffect & {
                    top: -11.5px;
                    @media screen and (min-width: 768px) {
                        top: -7.25px;
                    }
                    @media screen and (min-width: 1024px) {
                        top: 0;
                        left: -8px;
                    }
                }
            }

            svg {
                transform: rotateZ(-90deg);

                @media screen and (min-width: 1024px) {
                    transform: rotateZ(0);
                }
                path {
                    stroke: #9ff0ee;
                }
            }

            a {
                display: inline-block;
                padding: 15px;
                font-family: source-serif-pro, serif;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                color: $dark-teal;
                text-decoration: none;

                @media screen and (min-width: 768px) {
                    font-size: 16px;
                }
            }

        }
    }

    .logos-container {
        position: absolute;
        display: none;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        flex-shrink: 0;
        flex-grow: 0;
        bottom: 60px;
        right: 60px;
        width: 300px;
        z-index: 5;

        @media screen and (min-width: 1024px) {
            display: flex;
        }


        .logo-link {
            position: relative;
            width: 60%;
            height: auto;
            margin: 0px 20%;
            margin-bottom: 10px;
            text-align: center;
            text-decoration: none;
        }

        .logo-small-link {
            width: 25%;
            height: 30px;
            text-align: center;
            text-decoration: none;
            border-right: 1px solid $dark-teal;
            overflow: visible;

            &:last-of-type {
                border: none;
            }
        }

        .logo-laumeier {
            position: relative;
            width: 100%;
            height: auto;
            margin-left: -11%;
        }

        .logo-small {
            width: auto;
            max-width: 96%;
            height: auto;
            max-height: 100%;
        }

        .logo-aw {

        }

        .logo-nea {
            max-width: unset;
            max-height: unset;
            width: 86%;
            margin-top: -5%;
        }

        .logo-stlcp {
            width: 74%;
            margin-top: 2%;
        }

        .logo-pc {
            max-width: unset;
            max-height: unset;
            width: 33.333%;
            margin-left: -30%;
        }
    }

    // .laumeier-logo {
    //     display: none;
    //     z-index: 5;
    //
    //     @media screen and (min-width: 1024px) {
    //         display: block;
    //         position: absolute;
    //         bottom: 60px;
    //         right: 60px;
    //     }
    // }

    .map-drag {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 1600px;
        height: 900px;
        margin: 0;
        transform-origin: top center;
        //transition: transform 0.1s ease-in;
        z-index: 2;
    }

    #park-map {
        position: absolute;
        top: 0;
        left: 0;
        width: 1600px;
        height: 900px;
        margin: 0 auto;
        transform-origin: top center;
        //transition: transform 0.1s ease-in;
        z-index: 1;

        #roads-paths {
            position: absolute;
            top: -10px;
            left: 105px;
            right: 0;
            // width: 863.82px; // original
            // height: 919px;// original
            //width: calc(100vh * (863.82 / 919)); // responsive height * (original width / original height)
            //height: 100vh;
            width: 845.96px; // scaled to 900px height
            height: 900px;
            margin: 0 auto;

            path, rect {
                fill: $dark-teal;
            }
        }

        #map-buildings {
            position: absolute;
            top: 114px;
            left: -27px;
            right: 0;
            margin: 0 auto;
            text-align: center;
            pointer-events: none;
            z-index: 1;


            #buildings {
                height: 160px;
            }

            #shadows {
                position: absolute;
                top: 1px;
                left: 0;
                right: 0;
                height: 160px;
                margin: 0 auto;
                z-index: 0;
            }
        }
    }

    #map-dots-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 1600px;
        height: 900px;
        margin: 0;
        pointer-events: none;
        transform-origin: top center;
        //transition: transform 0.1s ease-in;
        z-index: 3;

        .dot-container {
            position: absolute;
            top: 800px;
            left: 500px;
            width: auto;
            height: 30px;
            opacity: 0.15;
            outline: none;
            pointer-events: none;
            touch-action: none;
            transition: opacity 0.5s ease-in-out;
            z-index: 1;

            &:hover, &.touch-active {
                z-index: 5;
            }

            &.touch-active {
                z-index: 5;

                &.filter-active {
                    .dot-label-wrapper {
                        pointer-events: all;
                        touch-action: auto;
                    }
                }
            }
            &.filter-active {
                opacity: 1;
                pointer-events: all;
                touch-action: auto;

                a.dot {
                    pointer-events: all;
                    touch-action: auto;
                }
            }

            &.sculpture {
                svg path[stroke*="0"],
                svg path[STROKE*="0"] {
                    stroke: $yellow !important;
                }
                svg path[fill*="0"],
                svg path[FILL*="0"] {
                    fill: $yellow !important;
                }
                .dot-label {
                    background-color: $yellow;
                }
            }

            &.story {
                svg path[stroke*="0"],
                svg path[STROKE*="0"] {
                    stroke: $purple !important;
                }
                svg path[fill*="0"],
                svg path[FILL*="0"] {
                    fill: $purple !important;
                }
                .dot-label {
                    color: $pale-teal;
                    background-color: $purple;
                }
                .dot-arrow polygon {
                    fill: $pale-teal;
                    stroke: $pale-teal;
                }
            }
            &.milestones {
                svg path[stroke*="0"],
                svg path[STROKE*="0"] {
                    stroke: $green !important;
                }
                svg path[fill*="0"],
                svg path[FILL*="0"] {
                    fill: $green !important;
                }
                .dot-label {
                    background-color: $green;
                }
            }
        }
        a.dot {
            position: relative;
            display: block;
            width: 30px;
            height: 30px;
            outline: none;
            overflow: hidden;
            pointer-events: none;
            touch-action: none;
            z-index: 1;
        }

        .dot-label-wrapper {
            position: absolute;
            display: block;
            top: 0;
            left: 30px;
            height: 100%;
            width: auto;
            pointer-events: none;
            touch-action: none;
            overflow: hidden;
            z-index: 2;
        }

        .dot-label {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: auto;
            height: 100%;
            margin: 0;
            padding: 0 5px;
            white-space: nowrap;
            font-family: acumin-pro-extra-condensed, sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 27px;
            color: $dark-teal;
            transform: translateX(-101%);
            pointer-events: none;
            touch-action: none;
            z-index: 1;
        }

        svg.dot-arrow {
            position: relative;
            width: 12px;
            height: 12px;
            margin: auto auto -1px 10px;
            fill: $dark-teal;
            stroke: $dark-teal;
            stroke-width: 20px;
            z-index: 1;
        }

        .bodymovin-wrapper {
            height: 30px;
            width: 30px;
        }

        .bodymovin-dot {
            svg {
                /* (original width * (desired width / original height)) fixes bug in Safari */
                width: calc(249px * (30 / 45)) !important;
            }
        }

    }

    #map-intro-text {
        position: absolute;
        display: block;
        top: 120px;
        left: 0;
        right: 0;
        width: 90%;
        max-width: 380px;
        margin: 0 auto;
        padding: 30px 2% 40px 2%;
        font-family: freight-text-pro, serif;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: $dark-teal;
        background: rgba($medium-teal, 0.9);
        box-shadow: 0px 0px 38px -1px rgba(0,0,0,0.23);
        z-index: 6;

        @media screen and (min-width: 768px) {
            top: 200px;
            left: 40px;
            right: unset;
            margin: 0;
            padding: 30px 25px 40px 25px;
        }

        @media screen and (min-width: 1024px) {
            top: 175px;
        }

        .close-box {
            background: none;
            border: none;
            font-size: 27px;
            color: #28b0ab;
            position: absolute;
            top: 15px;
            right: 20px;
            z-index: 10;
            cursor: pointer;

            &:focus {
                outline-style: dotted;
                outline-width: 1px;
                outline-color: #28b0ab;
            }
        }

        .start-exploring {
            display: block;
            width: 100%;
            margin-top: 40px;
            font-family: acumin-pro-extra-condensed, sans-serif;
            font-style: normal;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            font-size: 24px;
            color: $pale-teal;
            background: none;
            border: none;
            cursor: pointer;

            &:focus {
                outline-style: dotted;
                outline-width: 1px;
                outline-color: $pale-teal;
            }
        }

        .info-year {
            position: absolute;
            top: 55px;
            left: 0;
            right: 0;
            text-align: center;
            font-family: acumin-pro-extra-condensed, sans-serif;
            font-size: 137px;
            font-style: normal;
            font-weight: 700;
            color: $light-teal;
            z-index: 1;
        }

        .blurb-text {
            margin: 30px auto auto auto;
            padding: 0 10%;
        }

        .decade-text {
            // position: relative;
            // display: block;
            // width: 100%;
            // text-align: center;
            // z-index: 2;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            text-align: center;
            z-index: 2;

            .laumeier-scuplture-park {
                display: block;
                font-family: freight-text-pro, serif;
                font-style: normal;
                font-weight: 500;
                font-size: 19px;
                text-align: center;
            }

            .through-the-decades {
                // display: block;
                // width: 100%;
                // font-family: acumin-pro-extra-condensed, sans-serif;
                // font-style: normal;
                // font-weight: 700;
                // font-size: 43px;
                // text-transform: uppercase;
                // margin-top: -99px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 43px;
                margin: 0 auto;
                font-family: acumin-pro-extra-condensed, sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 43px;
                line-height: 0;
                text-transform: uppercase;

                @media screen and (min-width: 768px) {
                    height: 47px;
                    font-size: 47px;
                }

                .the {
                    // display: inline-block;
                    // writing-mode: tb-rl;
                    // transform: scale(0.5,0.2);
                    // letter-spacing: 30px;
                    // margin-left: 1px;
                    // margin-right: -11px;
                    // position: relative;
                    // top: 53px;

                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    flex-shrink: 0;
                    flex-grow: 0;
                    align-self: center;
                    width: 25px;
                    height: 70%;
                    margin: 11px auto auto auto;


                    span {
                        // display: inline-block;
                        // transform: rotate(-90deg);
                        display: block;
                        width: 100%;
                        height: 33.333%;
                        padding: 0;
                        margin: auto;
                        line-height: 0;
                        text-align: center;
                        transform: scale(0.5, 0.2);
                    }
                }

            }
        }
    }

}
