$img-path: '/img/';


$decade_filters: 60 70 80 90 00 10;
$article_filters: sculpture story milestones;


$articles:(
    sculpture: #fdd000,
    story: #9d38fb,
    milestones: #26f7c3
);
