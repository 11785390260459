* {
    box-sizing: border-box;
    -ms-overflow-style: -ms-autohiding-scrollbar; /* auto hide scrollbar in IE */
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html,
body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

main {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}
