/* MIXINS ----------------------------------------------------------------------*/

@mixin modalForegroundAnimation() {
    &.unfold-animation-in {
        opacity: 0;
        animation-name: unfoldIn;
        animation-duration: 0.5s;
        animation-delay: 0.5s;
        animation-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;

        &.unfold-animation-out {
            animation-name: unfoldOut;
            animation-duration: 0.5s;
            animation-delay: 0s;
            animation-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
            animation-iteration-count: 1;
            animation-direction: normal;
            animation-fill-mode: forwards;
        }
    }
}

@mixin modalBackgroundAnimation() {
    &.unfold-animation-in {
        opacity: 0;
        animation-name: scaleIn;
        animation-duration: 0.5s;
        animation-delay: 0s;
        animation-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;

        &.unfold-animation-out {
            opacity: 1;
            animation-name: unfoldOut2;
            animation-duration: 0.5s;
            animation-delay: 0.5s;
            animation-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
            animation-iteration-count: 1;
            animation-direction: normal;
            animation-fill-mode: forwards;
        }
    }
}

@mixin contentBlurAnimation() {
    &.unfold-animation-in {
        animation-name: blurIn;
        animation-duration: 0.5s;
        animation-delay: 0s;
        animation-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;

        &.unfold-animation-out {
            animation-name: blurOut;
            animation-duration: 0.5s;
            animation-delay: 0.5s;
            animation-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000);
            animation-iteration-count: 1;
            animation-direction: normal;
            animation-fill-mode: forwards;
        }
    }
}

@mixin animateToPrevious() {
    animation-name: exitToLeft;
    animation-duration: 0.5s;
    animation-delay: 0s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}
@mixin animateFromPrevious() {
    animation-name: enterFromLeft;
    animation-duration: 0.5s;
    animation-delay: 0s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}
@mixin animateToNext() {
    animation-name: exitToRight;
    animation-duration: 0.5s;
    animation-delay: 0s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}
@mixin animateFromNext() {
    animation-name: enterFromRight;
    animation-duration: 0.5s;
    animation-delay: 0s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
}



/* ANIMATIONS ------------------------------------------------------------------*/

@keyframes unfoldIn {
    0% {
        opacity: 1 !important;
        transform: scaleY(.005) scaleX(0);
    }

    50% {
        transform: scaleY(.005) scaleX(1);
    }

    99% {
        transform: scaleY(1) scaleX(1);
    }
    100% {
        opacity: 1;
        transform: unset !important;
    }
}
@keyframes unfoldOut {
    0% {
        opacity: 1;
        transform: unset !important;
    }
    1% {
        transform: scaleY(1) scaleX(1);
    }

    50% {
        transform: scaleY(.005) scaleX(0.5);
    }

    100% {
        opacity: 1;
        transform: scaleY(.005) scaleX(0);
    }
}
@keyframes unfoldOut2 {
    0% {
        opacity: 1;
        transform: unset !important;
    }
    1% {
        transform: scaleY(1) scaleX(1);
    }

    50% {
        transform: scaleY(.010) scaleX(1);
    }

    100% {
        opacity: 1;
        transform: scaleY(.005) scaleX(0);
    }
}
@keyframes scaleIn {
    0% {
        opacity: 1;
        transform: scaleY(0) scaleX(0);
    }

    99% {
        transform: scaleY(1) scaleX(1);
    }
    100% {
        opacity: 1;
        transform: unset !important;
    }
}

@keyframes blurIn {
    0% {
        filter: unset;
    }

    1% {
        filter: blur(0px);
    }

    100% {
        filter: blur(10px);
    }
}

@keyframes blurOut {
    0% {
        filter: blur(10px);
    }

    99% {
        filter: blur(0px);
    }

    100% {
        filter: unset;
    }
}

@keyframes exitToLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100vw);
    }
}
@keyframes enterFromLeft {
    0% {
        transform: translateX(-100vw);
    }

    100% {
        transform: translateX(0);
    }
}
@keyframes exitToRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100vw);
    }
}
@keyframes enterFromRight {
    0% {
        transform: translateX(100vw);
    }

    100% {
        transform: translateX(0);
    }
}
