body.about-modal-open {
    main {
        position: fixed;
    }

    .about-modal-background {
        display: block;
    }
    #about-modal {
        display: flex;
    }
}

body main {
    @include contentBlurAnimation;
}

/* if the filter property is not supported (IE) */
html.no-cssfilters {
    body {
        &.about-modal-open {
            main #sculpture-container .question-button {
                opacity: 0;
            }
        }

        main #sculpture-container .question-button {
            opacity: 1;
            transition: opacity 0.25s ease-in-out;
        }

        .about-modal-background {
            background-color: rgba(0, 0, 0, 0.65);
        }
    }
}

.about-modal-background {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    @include modalBackgroundAnimation();
    z-index: 5;
}

#about-modal {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: all;
    color: $pale-orange;
    overflow-y: auto;
    @include modalForegroundAnimation;
    -webkit-overflow-scrolling: touch; //add intertia scrolling to iOS
    z-index: 6;

    .about-modal-content {
        position: relative;
        width: 80%;
        max-width: 620px;
        height: auto;
        margin: 0 auto;
        padding: 0;
        z-index: 7;
				overflow: auto;
				padding: 100px 0;
    }

    h3 {
        margin-top: 10px;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 24px;
        line-height: 28px;

        @media screen and (min-width: 768px) {
            margin-top: 20px;
            font-size: 30px;
            line-height: 34px;
        }
    }

    .close-button {
        position: absolute;
        top: 30px; //synced up with question button
        right: 30px; //synced up with question button
        color: $pale-orange;
        z-index: 1000;

        @media screen and (min-width: 768px) {
            top: 60px; //synced up with question button
            right: 75px; //synced up with question button
        }

        @media screen and (min-width: 768px) {
            top: 60px; //synced up with question button
            right: 75px; //synced up with question button
        }

        svg {
            path {
                &[stroke*="0"],
                &[STROKE*="0"] //Edge uppercases known SVG attributes
                {
                    stroke: $pale-orange !important;

                    @media screen and (min-width: 1024px) {
                        stroke: $pale-orange !important;
                    }
                }
            }
        }
    }

}
