$pale-teal: #e7fffe;
$light-teal: #b4f9f7;
$medium-teal: #7beeea;
$teal: #47c4c2;
$dark-teal: #095e5c;

$pale-orange: #ffd084;
$orange: #ffb420;
$dark-orange: #ff8400;

$yellow: #fdd000;

$purple: #9d38fb;

$green: #26f7c3;
