$vertical-button-mobile-scale: 0.75;
$vertical-button-tablet-scale: 0.90;
$vertical-button-desktop-scale: 1.10;

$base-vertical-button-width: 48px;
$base-vertical-button-height: 52px;
$mobile-vertical-button-width: #{$base-vertical-button-width * $vertical-button-mobile-scale};
$mobile-vertical-button-height: #{$base-vertical-button-height * $vertical-button-mobile-scale};
$tablet-vertical-button-width: #{$base-vertical-button-width * $vertical-button-tablet-scale};
$tablet-vertical-button-height: #{$base-vertical-button-height * $vertical-button-tablet-scale};
$desktop-vertical-button-width: #{$base-vertical-button-width * $vertical-button-desktop-scale};
$desktop-vertical-button-height: #{$base-vertical-button-height * $vertical-button-desktop-scale};

$base-question-font-size: 27px;
$mobile-question-font-size: #{$base-question-font-size * $vertical-button-mobile-scale};
$tablet-question-font-size: #{$base-question-font-size * $vertical-button-tablet-scale};
$desktop-question-font-size: #{$base-question-font-size * $vertical-button-desktop-scale};

$base-close-font-size: 27px;
$mobile-close-font-size: #{$base-close-font-size * $vertical-button-mobile-scale};
$tablet-close-font-size: #{$base-close-font-size * $vertical-button-tablet-scale};
$desktop-close-font-size: #{$base-close-font-size * $vertical-button-desktop-scale};

.question-button, .close-button {
    position: relative;
    display: block;
    width: $mobile-vertical-button-width;
    height: $mobile-vertical-button-height;
    font-family: acumin-pro-extra-condensed, sans-serif;
    font-style: normal;
    font-weight: 700;
    text-decoration: none;
    font-size: 0;
    line-height: 0;
    outline: none;
    background: none;
    cursor: pointer;
    pointer-events: all;
    touch-action: auto;

    @media screen and (min-width: 768px) {
        width: $tablet-vertical-button-width;
        height: $tablet-vertical-button-height;
    }

    @media screen and (min-width: 1024px) {
        width: $desktop-vertical-button-width;
        height: $desktop-vertical-button-height;
    }

    &:after {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin: auto;
        text-align: center;
    }
}

.question-button:after {
    content: '?';
    margin-top: -5%; //offset to even out vertically
    font-size: $mobile-question-font-size;
    @media screen and (min-width: 768px) {
        font-size: $tablet-question-font-size;
    }
    @media screen and (min-width: 1024px) {
        font-size: $desktop-question-font-size;
    }
}

.close-button:after {
    content: '×';
    margin-top: -10%; //offset to even out vertically
    font-size: $mobile-close-font-size;
    @media screen and (min-width: 768px) {
        font-size: $tablet-close-font-size;
    }
    @media screen and (min-width: 1024px) {
        font-size: $desktop-close-font-size;
    }
}



.start-exploring-button {
    display: block;
    padding: 15px;
    width: 188px;
    font-family: acumin-pro-extra-condensed, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: $dark-teal;

    &:before {
        content: 'Start Exploring';
        position: relative;
    }

    &:after {
        content: '▽';
        position: absolute;
        display: block;
        bottom: 7.5px;
        left: 0;
        right: 0;
        width: 100%;
        height: 0px;
        margin: 0 auto;
        font-size: 6px;
        font-weight: normal;
        transform: scale(3, 2);

        html.no-vectoreffect & {
            bottom: 5.5px;
        }
    }

    svg {
        bottom: -2px;

        path {
            stroke: $dark-teal;
        }
    }
}

.back-to-top-button {
    position: absolute;
    display: block;
    width: 102px;
    margin: auto;
    padding: 10px;
    font-size: 15px;
    font-family: source-serif-pro, serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: $dark-teal;
    text-decoration: none;

    &:after {
        content: 'Back to Top';
        position: relative;
    }

    @media screen and (min-width: 1024px) {
        width: 76px;
        font-size: 10px;
    }

    svg {
        bottom: 0;

        path {
            stroke: #9ff0ee;
        }
    }
}
